var ListGraphs;

(function($){

	ListGraphs = {

		$ : null,
        $wrapper : null,

		init : function(){

			this.$ = $( '.list-graphs' );
			if( !this.$.length ) return true;

            this.$.each(function(){

                this.$ = this.$ || $( this );
                this.$.addClass( 'calculating' );

                const data = {
                    $items : this.$.children( 'li' ),
                    $wrapper : this.$.parents( 'section,article' ).first()
                };
                // Bigger
                data.$bigger = data.$items.last();
                data.$biggerYear = data.$bigger.children( 'em' );
                data.$biggerVal = data.$bigger.children( 'strong' );
                data.$biggerGraph = data.$bigger.children( 'span' );
                data.biggerGraphWidth = Math.floor( data.$bigger.outerWidth( true ) - data.$biggerYear.outerWidth( true ) - data.$biggerVal.outerWidth( true ) );

                // Smaller
                data.$small = data.$items.first();
                data.$smallVal = data.$small.children( 'strong' );
                data.$smallGraph = data.$small.children( 'span' );
                data.smallerGraphWidth = Math.floor( data.biggerGraphWidth * ( data.$smallVal.data( 'value' ) * 100 / data.$biggerVal.last().data( 'value' ) / 100 ) );

                this.$
                    .data( 'ListGraphs', data )
                    .removeClass( 'calculating' )
                ;

                // Binders
                data.$wrapper.on( 'showing', function(){
                    data.$biggerGraph.css({ 'width': data.biggerGraphWidth  + 'px' });
                    data.$smallGraph.css({ 'width': data.smallerGraphWidth  + 'px' }); 
                });
                data.$wrapper.on( 'hiding', function(){
                    data.$biggerGraph.css({ 'width': '1px' });
                    data.$smallGraph.css({ 'width': '1px' }); 
                });

            });
            
		}

	};

	$(function(){
		ListGraphs.init();
	})

})(jQuery);	
