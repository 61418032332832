(function($){

    var _window;

    var Timeslider = {

        $ : null,

        init : function(){

            var $timeslider = $( '.timeslider' );
            if( !$timeslider.length ) return true;

            var data =  {
                $ : $timeslider.first(),
                $slider : $( '.timeslider-items', $timeslider ),
                $tabs : $( '.tabs-controls ul button', $timeslider ),
                $controls : $( '.tabs-controls', $timeslider ),
                $dots : $( '.dots', $timeslider ),
                year : null
            };
            data.totalTabs = data.$tabs.length;
            data.$arrows = $( '.arrows button', data.$controls );
            data.$arrowPrev = $( '.arrows button.prev', data.$controls );
            data.$arrowNext = $( '.arrows button.next', data.$controls );

            var slickSettings;
            if( Utils.vWidth() > 768 ){

                slickSettings = {                    
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    infinite : false
                }
            
            }
            else {
            
                slickSettings = {                    
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    appendDots: data.$dots,
                    infinite : false
                }
            
            }
            data.year = data.$tabs.first().text();
            data.$slider
                .on( 'init', function( event, slick ){
                    setTimeout(function(){
                        if( data.$tabs.length ){
                            Timeslider.filter( data );
                        }
                    }, 10 );
                })
                .slick( slickSettings )
            ;

            data.$arrows.on( 'click', function(){

                this.$ = this.$ || $( this );

                if( this.$.prop( 'disabled' ) ) return true;

                var 
                    sentido = this.$.hasClass( 'prev' ) ? 'prev' : 'next',
                    $active = data.$tabs.filter( '.active' ),
                    $toClick = false,
                    activeIndex = $active.parent().index(),
                    newIndex
                ;

                if( activeIndex === 0 ){
                    newIndex = sentido === 'next' ? activeIndex + 1 : ( data.totalTabs - 1 );
                }
                else if( activeIndex === ( data.totalTabs - 1 ) ){
                    newIndex = sentido === 'prev' ? activeIndex - 1 : 0;
                }
                else {
                    newIndex = sentido === 'next' ? ( activeIndex + 1 ) : ( activeIndex - 1 );
                }

                data.$tabs.eq( newIndex ).trigger( 'click' );
                

            });

            data.$tabs.on( 'click', function(){
                Timeslider.bindIndiceClick( data, this );
            });
            
        },

        filter : function( data ){

            var slider = $( '.timeslider .timeslider-items' ).first();

            slider.slick( 'slickUnfilter' );
            slider.slick( 'slickFilter', function(){
                this.$ = this.$ || $( this );
                return this.$.find( '.card[data-year="'+ data.year +'"]' ).length;
            });
            
        },

        bindIndiceClick : function( data, button ){
            
            button.$ = button.$ || $( button );

            if( button.$.hasClass( 'active' ) ) return true;
            
            data.$tabs.removeClass( 'active' );
            data.year = button.$.text();
            
            Timeslider.filter( data );

            button.$.addClass( 'active' );
            Timeslider.checkArrows( data );

        },

        checkArrows : function( data ){
            var 
                $active = data.$tabs.filter( '.active' ),
                activeIndex = $active.parent().index()
            ;
            if( activeIndex === 0 ){
                data.$arrowPrev.prop( 'disabled', true );
            }
            else {
                data.$arrowPrev.prop( 'disabled', false );
            }
            
            if( activeIndex === ( data.totalTabs - 1 ) ){
                data.$arrowNext.prop( 'disabled', true );
            }
            else {
                data.$arrowNext.prop( 'disabled', false );
            }
        }

    };
    $(function(){       
        Timeslider.init();
    });

})(jQuery);
