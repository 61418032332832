window.backhash = window.location.hash === '' || window.location.hash === '#' || window.location.hash.indexOf( '=' ) > -1 ? false : window.location.hash;
window.backurl = window.location.href;
if( window.backhash ){
	window.location.hash = '';
	window.scrollTo( 0, 0 );
}

var 
	Navigation,
	_body,
	_window
;

(function($){

	Navigation = {

		$navs : null,
		$menu : null,
		$toggle : null,
		$header : false,
		timeout : false,
		$triggers : null,
		targets : {},
		totalTargets : 0,
		lastScollTop : 0,
		offseLimit : true,
		timeResize : false,
		timeClose : 1000,
		fixedScroll : 0,
		lockedScroll : false,
		scrollBinded : false,
		counter: 1,

		components : [],

		init : function(){

			// Body
			if( _body === undefined ){
				_body = $( 'body' );
			} 

			// Window
			if( _window === undefined ){
				_window = $( window );
			}

			// Elements
			this.$menu = $( '#menu' );
			this.$header = $( 'body > header' );

			// Toggle
			this.$toggle = $( '[data-action="toggle-menu"]' );
			this.$toggle.on( 'click', function(e){
				e.preventDefault();
				Navigation.menuToggle();
			});

	    	// Check when resize
	    	$( window ).resize(function(){
	    		// Time resizing
	    		if( Navigation.timeResize ) clearTimeout( Navigation.timeResize );
	    		// Start timer
	    		Navigation.timeResize = setTimeout(function(){
	    			// Check items
					Navigation.checkItems();
	    		}, 100 );
	    	});
			Navigation.checkItems();

			// Initial hash
			if( window.backhash ) {
	        	var $hashTarget = $( window.backhash );
	        	if( $hashTarget.length ){
	        		Navigation.restoreHash( $hashTarget );
	        	}
			}
			else {
				$( 'html,body' ).css( 'scroll-behavior', 'smooth' );
			}

		},

		menuToggle : function(){
			if( _body.hasClass( 'menu-opened' ) ){
				this.menuClose();
			}
			else {
				this.menuOpen();
			}
		},

		menuOpen : function(){
		
			_body.addClass( 'menu-opened' );
			Navigation.lockScroll();

		},

		menuClose : function(){
			_body.removeClass( 'menu-opened' );
			Navigation.unlockScroll();
		},

		isLockedScroll : function(){
			return Navigation.lockedScroll === true;
		},

		lockScroll : function(){
			Navigation.lockedScroll = true;
			Navigation.fixedScroll = Navigation.getPageTop();
			$( 'html' ).addClass( 'locked-scroll' );
		},
		
		unlockScroll : function(){
			Navigation.lockedScroll = false;
			$( 'html' ).removeClass( 'locked-scroll' );
		},

		// Restore hash
		restoreHash : function( $el, scroll ){

			$( 'html,body' ).css( 'scroll-behavior', 'initial' );

			// Element ID backup
    		var elemnetID = $el.attr( 'id' );

            // Prevent default page scroll
            $el.attr( 'id', '' );
            // Restore hash
            window.location.hash = elemnetID;

            // Restore element ID
            $el.attr( 'id', elemnetID );

            $( 'html,body' ).css( 'scroll-behavior', 'smooth' );

            // Scroll to element
            if( scroll === undefined || scroll !== false ){
				setTimeout(function(){
		        	Navigation.scrollTo( $el );
				}, 1 );
            }
		
		},

		// Bind click
		clickMenuItem : function( data, item ){
			Navigation.menuClose();
		},

		closeMenu : function( data ){
			data.$.addClass( 'closed' );
		},

		openMenu : function( data ){

			data.$.removeClass( 'closed' );
		
		},

	    vWidth : function vWidth(){
	        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	    },

	    vHeight : function vHeight(){
	        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	    },

	    getPageTop : function(){
	    	var top = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 );
	    	// if( this.$header.length ){
	    	// 	top += this.$header.outerHeight();
	    	// }
	    	return top;
	    },

		getPageHeight : function(){
		    var doc = document;
		    return  Math.max(
		        doc.body.scrollHeight, doc.documentElement.scrollHeight,
		        doc.body.offsetHeight, doc.documentElement.offsetHeight,
		        doc.body.clientHeight, doc.documentElement.clientHeight
		    );
		},

		getScrollInfo : function(){

			var 
				pageHeight = this.getPageHeight(),
				pageTop = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 ),
				viweportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
				viweportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
				realPercuse = Math.ceil( pageTop + viweportHeight )
			;

			return {
				pageHeight : pageHeight,
				pageTop : pageTop,
				viweportHeight : viweportHeight,
				realPercuse : realPercuse,
				viweportWidth : viweportWidth
			};
		
		},

		checkIfBottom : function(){

			var scrlInfo = Navigation.getScrollInfo();
			return scrlInfo.realPercuse >= scrlInfo.pageHeight;

		},

		isSafari : function(){
			var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			return isSafari;
		},

		// Configure navigation
		checkItems : function(){

			// Navigations
			this.$contents = $( 'body > main' ).children( '[id]' );
			if( !this.$contents.length ) return true;

			// Clearing
			Navigation.targets = [];
			Navigation.positions = [];
			Navigation.totalTargets = 0;
			Navigation.$contents.each(function(){
				
				this.$ = this.$ || $( this );

				// Get top position
				var top_position = parseInt( this.$.offset().top );
				if( top_position < 0 ) top_position = 0;

				// Insert in targets array
				if( !Navigation.positions.includes( top_position ) ){
					Navigation.positions.push( top_position );
				}

				// Insert in targets array
				var key_atgts = 'tgts-' + top_position;
				if( Navigation.targets[ key_atgts ] === undefined ){
					Navigation.targets[ key_atgts ] = [];
				}
				Navigation.targets[ key_atgts ].push( this.$ );

				// Increment total
				Navigation.totalTargets++;

			});

    		if( Navigation.totalTargets > 0 ){
    	
	            // Ordering positions
    			Navigation.positions = Navigation.positions.sort(function( a, b ){ return a - b });    		

		    	// Binding scroll
	            Navigation.checkScroll( true );
	            _window.bind( 'scroll', function(){
	                Navigation.checkScroll();
	            });

    		}
    		
		},

	    checkScroll : function( initing ){

	    	// Top page positioun
	    	var scrollTop = Navigation.getPageTop();

	    	// Direction
            var dir = scrollTop >= Navigation.lastScrolTop ? 'down' : 'up';

			// Start calculate
	    	var 
	    		start = Navigation.positions[ 0 ],
	    		items_key = false
	    	;

	    	// At top
	    	if( scrollTop <= start ){
	    		items_key = start;
	    	}
	    	// At bottom
	    	else if( Navigation.checkIfBottom() ){
				items_key = Navigation.positions[ Navigation.positions.length - 1 ];
	    	}
	    	// Calculate positions
	    	else {

	    		// Offset
	    		var top_compare = scrollTop;

	    		// Section scroll offset
	    		var _offset_css = Navigation.$contents.first().css( 'scroll-margin-top' );
	    		_offset_css = _offset_css === undefined ? 0 : parseInt( _offset_css );
	    		
	    		if( Navigation.offseLimit ){
	    			// Scroll margin offset
		    		if( _offset_css > 0 && start > 0 ){
		    			top_compare -= _offset_css;
		    		}
	    			// Others browsers
	    			if( !Navigation.isSafari() ){
		    			var _offset = Math.max( document.documentElement.clientHeight || 0, window.innerHeight || 0 ) / 4;

		    			if( dir === 'down' ){
		    				top_compare += _offset;
		    			}
		    			else {
		    				top_compare += _offset * 2;
		    			}
	    			}
	    			else {
						top_compare += _offset_css;
	    			}
	    		}
	    	
	    		// Calculate
	    		Navigation.positions.forEach(function( position ){
		    		if( top_compare >= ( parseInt( position ) - start ) ){			    	
		    		   	items_key = position;
		    		}
	    		});
	    	
	    	}

	    	// Marking classes
	    	var targets_keys = Object.keys( Navigation.targets );

	    	// Mark items
	    	var key_atgts = 'tgts-' + items_key;

	    	// Check and unchek
	    	targets_keys.forEach(function( tgtKey ){
	    		if( Navigation.targets[ tgtKey ] !== undefined ){
					// Showing
	    			if( tgtKey === key_atgts ){
						Navigation.targets[ tgtKey ].forEach(function( target ){
							// Once
							if( !target.hasClass( 'animated' ) ){
								target.addClass( 'animated' );
							}
							if( !target.hasClass( 'active' ) ){
					    		target.trigger( 'showing' );
								target.addClass( 'active' );
							}
			    		});
	    			}
	    			// Hidding
	    			else {
						Navigation.targets[ tgtKey ].forEach(function( target ){
							if( target.hasClass( 'active' ) ){
					    		target.trigger( 'hiding' );
								target.removeClass( 'active' );
							}
			    		});
	    			}
		    	}
	    	});

			Navigation.lastScrolTop = scrollTop;

	    },

	    scrollTo : function( selector, callback ){

	        var $target = typeof( selector ) === 'object' ? selector : $( selector );            
	        if( !$target.length ) return;

			var newY;
	        if( $target.attr( 'id' ) === 'inicio' ){
	        	newY = 0;
	        }
	        else {
		        newY = $target.offset().top;
	        }

	        var actualY = _window.scrollTop();
	        var distance = actualY - newY;

	        if( distance < 0 ) distance *= -1;
	        
	        var time = ( 600 - ( distance * 0.1 ) );
	        if( time < 600 ) time = 600;
	        
	        var offset = $target.data( 'offset' );
	        if( offset ){

	            try {

	                var 
	                    _json = JSON.parse( offset.split( "'" ).join( '"' ) )
	                    _offset = false,
	                    _vWidth = Navigation.vWidth()
	                ;

	                $.each( _json.breaks, function( _break ){
	                    if( _vWidth <= _break ){
	                        _offset = parseInt( this );
	                        return false;
	                    }
	                });

	                offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

	            }
	            catch( e ){

	                var _offset = parseInt( offset );
	                offset = _offset > 0 ? _offset : 0;
	            
	            }

	        }
	        else {

	        	var vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	        	offset = vw >= 992 ? 62 : 55;
	        
	        }

	        window.scrollTo( 0, ( newY - ( offset ) ) );

	    }
		
	};

	$(function(){
		$( 'body' ).imagesLoaded(function(){
			Navigation.init();
		});
	});

})(jQuery);

