(function($){

    var _window;

    var SUcom = {

        $ : null,
        $header  : null,
        $menu : null,
        $video : null,
        lastScrolTop : false,
        timeResize : false,
        currentMouseY : -1,
        lastGadget : false,

        init : function(){

            _window = $( window );

            SUcom.$ = $( 'body' );
            if( !SUcom.$.length ) return true;

            SUcom.$header = SUcom.$.children( 'nav' ).first();
            SUcom.$menu = $( '#menu', SUcom.$ ).first();

            // Vídeo
            SUcom.$video = $( '#video-wrapper' ).first();

            //  Binders
            SUcom.bindHeader();
            SUcom.bindTimeline();
            SUcom.bindLegados();
            SUcom.bindSanfona();

            if( Utils.vWidth() <= 480 ){
                SUcom.bindMobSliders();
            }

            if( Utils.isMobile() ){
                SUcom.mobileHeights();
            }

            // Cards hover gif
            $( '.hover-gifs .card' ).on( 'mouseenter', function(){

                this.$ = this.$ || $( this );
                this.$gif = this.$gif || this.$.find( 'img' );
                this.gif_url = this.$gif.attr( 'src' );
                this.$gif.attr( 'src', 'about:blank' ).attr( 'src', this.gif_url );

            });
            
        },

        bindMobSliders : function(){

            var $sliders = $( '.slider-when-mobile' );
            if( $sliders.length ){
                $sliders.slick({
                    arrows: false,
                    dots: true,
                    adaptiveHeight: true
                });
            }

        },

        mobileHeights : function(){
            var vHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        },

        scrollHeight : function(){
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        },

        // Binding header
        bindHeader : function(){
            // Close menu
            $( 'button', SUcom.$menu ).on( 'click', function(){
                SUcom.closeMenu();
            });
            $( '#menu a', SUcom.$menu ).on( 'click', function(){
                SUcom.closeMenu();
            });
        },

        // Toggle mobile menu
        toggleMenu : function(){
            if( SUcom.$.hasClass( 'menu-opened' ) ){
                SUcom.closeMenu();
            }
            else {
                SUcom.openMenu();
            }
        },

        // Open menu mobile
        openMenu : function(){
            SUcom.$menuToggle.addClass( 'opened' );
            SUcom.$.addClass( 'menu-opened' );
        },

        // Close menu mobile
        closeMenu : function(){
            SUcom.$menuToggle.removeClass( 'opened' );
            SUcom.$.removeClass( 'menu-opened' );
        },

        bindSanfona : function(){

            // FAQ
            $( '.piki-sanfona' ).pikiSanfona({      
                items: '.sanfona-item',
                headers: '.item-header',
                contents: '.item-content',
                openFirst: false
            });

        },

        bindTimeline : function(){

            var $timeline = $( 'div.timeline' );
            if( !$timeline.length ) return true;

            var 
                $controls = $( '.tabs-controls', $timeline ),
                $prev = $( 'button.prev', $controls ),
                $next = $( 'button.next' , $controls)
            ;

            $( '.timeline-slider', $timeline ).slick({
                prevArrow: $prev,
                nextArrow: $next,
                infinite: false,
                dots: true,
                appendDots: $( '.indices-wrapper', $controls ),
                dotsClass: 'indices',
                customPaging: function( slick, index ){
                    var year = slick.$slides.eq( index ).find( '.timeline-item' ).data( 'ano' );
                    return '<button>'+ year +'</button>';
                }
            });

        },

        bindLegados : function(){

            var $legados = $( '#grandes-legados' );
            if( !$legados.length ) return true;

            var $slider  = $( '.slider', $legados );
            var $nav = $( '#sldnav', $legados );
            var $playPause = $( 'button.play-pause', $nav );

            // On init
            $slider.on( 'init', function( event, slick ){
                var $active = slick.$dots.children( 'li.slick-active' ).removeClass( 'slick-active' );
                setTimeout(function(){
                    $active.addClass( 'slick-active' );
                }, 1 );
            });
            $slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                fade: true,
                arrows: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 10000,
                adaptiveHeight: true,
                appendDots: '#sldnav',
                pauseOnFocus: false,
                pauseOnHover: false,
                pauseOnDotsHover: false
            });

            $playPause.on( 'click', function(e){

                e.preventDefault();

                if( $nav.hasClass( 'paused' ) ){
                    $slider.slick( 'slickPlay' );
                    $nav.removeClass( 'paused' );
                }
                else {
                    $slider.slick( 'slickPause' );
                    $nav.addClass( 'paused' );
                }

            });
        
        },

        scrollTo : function( selector ){

            var $target = typeof( selector ) === 'object' ? selector : $( selector );            
            if( !$target.length ) return;

            var newY = $target.offset().top;
            var actualY = _window.scrollTop();
            var distance = actualY - newY;
            if( distance < 0 ) distance *= -1;

            var time = ( 600 - ( distance * 0.1 ) );
            if( time < 600 ) time = 600;
            
            var offset = $target.data( 'offset' );
            if( offset ){

                try {

                    var 
                        _json = JSON.parse( offset.split( "'" ).join( '"' ) )
                        _offset = false,
                        _vWidth = Utils.vWidth()
                    ;

                    $.each( _json.breaks, function( _break ){
                        if( _vWidth <= _break ){
                            _offset = parseInt( this );
                            return false;
                        }
                    });

                    offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

                }
                catch( e ){
                    var _offset = parseInt( offset );
                    offset = _offset > 0 ? _offset : 0;
                }

            }
            else {
                offset = 0;
            }

            $( 'html, body' ).animate({
                scrollTop: ( newY - ( offset + 30 ) )
            }, time );      

        }

    };
    $(function(){       
        SUcom.init();
    });

})(jQuery);
