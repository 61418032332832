(function($){

	var WidgetNews = {

		$ : null,

		init : function(){

			this.$ = $( '.baixe-o-livro' );
			if( !this.$.length ) return true;

			WidgetNews.bind();

		},

		bind : function(){

			// Scroll and position
			var _window = $(window);
            _window.resize(function(){
                WidgetNews.checkScroll();
            });
            _window.bind( 'scroll', function(){
                WidgetNews.checkScroll();
            });
            WidgetNews.checkScroll();

		},

		checkScroll : function(){
			
			const 
				scrollTop = ( window.pageYOffset || document.documentElement.scrollTop ) - ( document.documentElement.clientTop || 0 ),
				vpHeight = Math.max( document.documentElement.clientHeight || 0, window.innerHeight || 0 ),
				formTop = this.$.offset().top,
				limitBottom = ( formTop - vpHeight + this.$.outerHeight() )
			;

			if( scrollTop < limitBottom ){
				this.$.addClass( 'sticky' );
			}
			else {
				this.$.removeClass( 'sticky' );
			}

		}

	};
	$(function(){
		setTimeout(function(){
			WidgetNews.init();
		}, 100 );
	});

})(jQuery);
